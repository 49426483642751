import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';

function TopPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [imageHeight, setImageHeight] = useState(0);
  const [aspectRatio, setAspectRatio] = useState(0);
  const rightContentRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const img = new Image();
    img.src = require('../assets/topimage.jpg');
    
    img.onload = () => {
      if (rightContentRef.current) {
        const containerWidth = rightContentRef.current.offsetWidth;
        const containerHeight = rightContentRef.current.offsetHeight;
        const imageRatio = img.height / img.width;
        setAspectRatio(imageRatio);
        
        // containの場合の実際の表示サイズを計算
        const displayWidth = containerWidth;
        const displayHeight = displayWidth * imageRatio;
        
        if (displayHeight > containerHeight) {
          // 高さが制限される場合
          setImageHeight(containerHeight);
        } else {
          // 幅が制限される場合
          setImageHeight(displayHeight);
        }
      }
    };
  }, []);

  // 2ページ目表示時の自動遷移を制御
  useEffect(() => {
    if (currentPage === 2) {
      const timer = setTimeout(() => {
        navigate('/generate');
      }, 2000);

      return () => clearTimeout(timer); // クリーンアップ
    }
  }, [currentPage, navigate]);

  const handleNextPage = () => {
    setCurrentPage(2);
  };

  return (
    <div className="top-page">
      
      <div className={`page page-${currentPage}`}>
        {currentPage === 1 ? (
          <div className="first-page">
            <div className="content-left"
              style={{
                backgroundImage: `url(${require('../assets/description.png')})`,
                backgroundSize: 'contain',
                backgroundPosition: 'left top',
                backgroundRepeat: 'no-repeat',
                aspectRatio: aspectRatio ? `${1.0 / aspectRatio * 4.0 / 6.0}` : 'auto'
              }}
            >
              
              <div className="content-left-bottom">
                <button onClick={handleNextPage} className="next-button">
                  Generate
                </button>
              </div>

            </div>
            <div 
              ref={rightContentRef}
              className="content-right"
              style={{
                backgroundImage: `url(${require('../assets/topimage.jpg')})`,
                backgroundSize: 'contain',
                backgroundPosition: 'right top',
                backgroundRepeat: 'no-repeat',
                aspectRatio: aspectRatio ? `1 / ${aspectRatio}` : 'auto'
              }}
            />
          </div>
        ) : (
          <div className="second-page">
            <div className="second-page-content"
              style={{
                backgroundImage: `url(${require('../assets/button-back.png')})`,
                backgroundSize: 'fill',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Link to="/generate" className="generate-button">
                Generating...
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TopPage; 