import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GradientCanvas from './GradientCanvas';
import PanelControl from './PanelControl';
import DownloadDialog from './DownloadDialog';
import '../App.css';

function GradientCreator() {
  const [gradientValues, setgradientValues] = useState({
    warpRatio: 0.8,
    warpSize: 1,
    noiseRatio: 0.05,
    bgColor: "#000000",
    colors: ["#ff0000","#0000ff"],
    numberPoints: 2,
    randomNumber: 1,
    widthExport: 512,
    heightExport: 512,
  });
  
  const [downloadMostrar, setDownloadMostrar] = useState(false);
  const [download, setDownload] = useState(false);
  const [share, setShare] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setgradientValues((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const colorChange = (color, n) => {
    setgradientValues((prevState) => {
      let colors = prevState.colors;
      colors[n] = color;
      return {
        ...prevState,
        colors: colors,
      };
    });
  };
  
  const changeNumber = (event) => {
    const {id} = event.target;
    setgradientValues((prevState) => {
      let newColors = prevState.colors;
      let newNumberPoints = prevState.numberPoints;
      if(id === "+") {
        newNumberPoints++;
        newColors.push("#444444");
      } else {
        newNumberPoints--;
        newColors.splice(id,1);
      }
      return {
        ...prevState,
        numberPoints: newNumberPoints,
        colors: newColors,
      };
    });
  };

  return (
    <div className="App">
      <div className="creator-header">
        <Link to="/" className="back-button">← Back to Gallery</Link>
        <h1>Create Custom Gradient</h1>
      </div>
      {downloadMostrar ?
        <DownloadDialog 
          setShare={setShare} 
          setDownload={setDownload} 
          setDownloadMostrar={setDownloadMostrar} 
          gradientValues={gradientValues} 
          handleChange={handleChange} 
          download={download} 
          share={share}
        />
      : null}
      <div className="Contenedor">
        <GradientCanvas 
          share={share} 
          download={download} 
          setShare={setShare} 
          setDownload={setDownload} 
          gradientValues={gradientValues}
        />
        <PanelControl 
          setDownloadMostrar={setDownloadMostrar} 
          handleChange={handleChange} 
          changeNumber={changeNumber} 
          colorChange={colorChange} 
          gradientValues={gradientValues}
        />
      </div>
    </div>
  );
}

export default GradientCreator; 