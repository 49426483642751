import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import GradientCanvas from './GradientCanvas';
import '../App.css';
import ReactDOM from 'react-dom/client';
import ColorPicker from '@radial-color-picker/react-color-picker';
import '@radial-color-picker/react-color-picker/dist/style.css';

function GradientGallery() {
  const STEPS = {
    FIRST_SELECTION: 1,
    SECOND_SELECTION: 2,
    THIRD_SELECTION: 3,
    FINAL: 4
  };

  const STEP_CONFIG = {
    [STEPS.FIRST_SELECTION]: {
      title: "Choose 10 images",
      subtitle: "represent you (or someone else)",
      required: 10,
      total: 400,
      training: "1st TRAINING",
      backgroundColor: "#A091BC",
      borderColor: "#6B5899"
    },
    [STEPS.SECOND_SELECTION]: {
      title: "Choose 3 images",
      subtitle: "represent you (or someone else)",
      required: 3,
      total: 200,
      training: "2nd TRAINING",
      backgroundColor: "#91BAB5",
      borderColor: "#4A7F77"
    },
    [STEPS.THIRD_SELECTION]: {
      title: "Choose 1 image",
      subtitle: "represent you (or someone else)",
      required: 1,
      total: 100,
      training: "Last Choice",
      backgroundColor: "#A56270",
      borderColor: "#A52846"
    },
    [STEPS.FINAL]: {
      title: "Your Product",
      subtitle: "Generated with inquid",
      required: 0,
      total: 1,
      training: ""
    }
  };

  const [currentStep, setCurrentStep] = useState(STEPS.FIRST_SELECTION);
  const currentStepRef = useRef(STEPS.FIRST_SELECTION);
  const abortControllerRef = useRef(null);
  const [gradients, setGradients] = useState([]);
  const [gradientImages, setGradientImages] = useState([]);
  const [selectedGradients, setSelectedGradients] = useState(new Set());
  const [savedColors, setSavedColors] = useState([]);
  const [selectedFinalImage, setSelectedFinalImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [selectedColorIndex, setSelectedColorIndex] = useState(null);
  const selectedColorIndexRef = useRef(null);
  const [colorList, setColorList] = useState([]);
  const [currentHSL, setCurrentHSL] = useState({ h: 0, s: 100, l: 50 });
  const [unityInstance, setUnityInstance] = useState(null);
  const [isUnityLoading, setIsUnityLoading] = useState(false);
  const [isUnityLoaded, setIsUnityLoaded] = useState(false);
  const unityCanvasRef = useRef(null);
  const [noiseValue, setNoiseValue] = useState(0);
  const [waveValue, setWaveValue] = useState(0);
  const [regenerationKey, setRegenerationKey] = useState(0);
  const [colorNames, setColorNames] = useState({ title: '', options: [] });
  const [isLoadingColorNames, setIsLoadingColorNames] = useState(false);

  const MAX_SELECTIONS = STEP_CONFIG[currentStep].required;
  const TOTAL_GRADIENTS = STEP_CONFIG[currentStep].total;

  // HSLの範囲を定義する定数
  const COLOR_RANGES = {
    hue: {
      min: 0,
      max: 360    // 色相は全範囲を使用
    },
    saturation: {
      min: 30,    // 低めの彩度（あまり鮮やかすぎない）
      max: 70     // 中程度までの彩度（パステル感を保つ）
    },
    lightness: {
      min: 50,    // 高めの明度（明るい）
      max: 80     // かなり明るい（白っぽさを出す）
    }
  };

  // HSLからHEXに変換する関数（既存の関数を再利用）
  function hslToHex(h, s, l) {
    h = h / 360;
    s = s / 100;
    l = l / 100;
    let r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }

    const toHex = x => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  // 指定範囲内でランダムな数値を生成する関数（既存の関数を再利用）
  const getRandomFloat = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  // HSLの範囲内でランダムな色を生成する新しい関数
  const generateRandomColor = () => {
    const h = getRandomFloat(COLOR_RANGES.hue.min, COLOR_RANGES.hue.max);
    const s = getRandomFloat(COLOR_RANGES.saturation.min, COLOR_RANGES.saturation.max);
    const l = getRandomFloat(COLOR_RANGES.lightness.min, COLOR_RANGES.lightness.max);
    return hslToHex(h, s, l);
  };

  // グラデーション設定から画像URLを生成する関数
  const generateGradientImage = (gradient, isFirstBatch) => {
    return new Promise((resolve) => {
      const tempId = `GradientCanvas_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute';
      tempDiv.style.visibility = 'hidden';
      tempDiv.style.width = '512px';
      tempDiv.style.height = '512px';
      tempDiv.id = tempId;
      document.body.appendChild(tempDiv);

      const tempGradient = <div className="contenedorCanvas" id="contenedorCanvas">
        <div className="GradientCanvas" id={tempId}>
          <GradientCanvas
            gradientValues={{
              ...gradient,
              widthExport: 512,
              heightExport: 512,
            }}
            download={false}
            share={false}
            setDownload={() => {}}
            setShare={() => {}}
          />
        </div>
      </div>;

      const root = ReactDOM.createRoot(tempDiv);
      root.render(tempGradient);

      let attempts = 0;
      const maxAttempts = 50;
      const initialDelay = isFirstBatch ? 200 : 50;

      const checkCanvas = () => {
        const canvas = tempDiv.querySelector('canvas');
        attempts++;

        if (canvas) {
          const gl = canvas.getContext('webgl');
          
          if (gl && canvas.width === 512 && canvas.height === 512) {
            try {
              const imageUrl = canvas.toDataURL('image/png', 1.0);
              if (imageUrl.length > 100) {
                root.unmount();
                document.body.removeChild(tempDiv);
                resolve(imageUrl);
                return;
              }
            } catch (error) {
              setTimeout(checkCanvas, 20);
              return;
            }
          }
        }
        
        if (attempts < maxAttempts) {
          setTimeout(checkCanvas, 20);
        } else {
          console.error('Failed to generate canvas');
          root.unmount();
          document.body.removeChild(tempDiv);
          resolve(null);
        }
      };

      setTimeout(checkCanvas, initialDelay);
    });
  };

  // バッチ処理（バッチサイズ2）
  const generateGradientBatch = async (gradientConfigs, isFirstBatch) => {
    const maxRetries = 3;
    const results = new Array(gradientConfigs.length).fill(null);
    let remainingIndices = gradientConfigs.map((_, index) => index);

    while (remainingIndices.length > 0) {
      // 現在のステップが変更された場合はループを終了
      if (currentStepRef.current !== currentStep) {
        console.log('Step changed, stopping generation');
        return [];
      }

      const currentIndices = [...remainingIndices];
      for (const index of currentIndices) {
        // ステップ変更をチェック
        if (currentStepRef.current !== currentStep) {
          return [];
        }

        const gradient = gradientConfigs[index];
        try {
          const result = await generateGradientImage(gradient, isFirstBatch);
          if (result && result.length > 100) {
            const img = new Image();
            try {
              await new Promise((resolve, reject) => {
                img.onload = () => {
                  const canvas = document.createElement('canvas');
                  canvas.width = img.width;
                  canvas.height = img.height;
                  const ctx = canvas.getContext('2d');
                  ctx.drawImage(img, 0, 0);
                  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                  const data = imageData.data;
                  
                  let hasNonBlackPixels = false;
                  for (let i = 0; i < data.length; i += 4) {
                    if (data[i] > 5 || data[i + 1] > 5 || data[i + 2] > 5) {
                      hasNonBlackPixels = true;
                      break;
                    }
                  }
                  
                  if (hasNonBlackPixels) {
                    results[index] = result;
                    remainingIndices = remainingIndices.filter(i => i !== index);
                    resolve();
                  } else {
                    reject(new Error('Generated image is too dark'));
                  }
                };
                img.onerror = () => reject(new Error('Failed to load image'));
                img.src = result;
              });
            } catch (error) {
              continue;
            }
          }
        } catch (error) {
          continue;
        }
      }

      if (remainingIndices.length > 0 && currentStepRef.current === currentStep) {
        // console.log(`Retrying for ${remainingIndices.length} remaining images`);
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }

    return results.filter(result => result !== null);
  };

  // 選択されたグラデーションからパラメータを抽出する関数
  const extractParamsFromSelected = () => {

    const params = {
      colors: [],
      warpRatios: [],
      warpSizes: []
    };

    Array.from(selectedGradients).forEach(index => {
      const gradient = gradients[index];
      if (gradient) {
        // console.log('Processing gradient at index:', index, gradient);
        // 色の抽出
        const bgColor = gradient.bgColor.startsWith('#') ? gradient.bgColor : `#${gradient.bgColor}`;
        const gradientColors = gradient.colors.map(color => 
          color.startsWith('#') ? color : `#${color}`
        );
        params.colors.push(bgColor, ...gradientColors);
        
        // warpRatioとwarpSizeの抽出
        params.warpRatios.push(gradient.warpRatio);
        params.warpSizes.push(gradient.warpSize);
      }
    });
    
    // console.log('Extracted params:', params);
    return params;
  };

  // 配列からランダムに要素を取得する関数
  const getRandomFromArray = (array) => {
    return array[Math.floor(Math.random() * array.length)];
  };


  // Unity初期化のeffectを修正
  useEffect(() => {
    console.log('Unity initialization effect - State:', {
      // currentStep,
      // shouldReinitUnity,
      isUnityLoaded,
      isUnityLoading
    });

    const initUnity = async () => {
      if (!unityCanvasRef.current) {
        console.error("Unity canvas reference not available");
        return;
      }

      if (isUnityLoading) {
        console.log('Unity is already loading, skipping initialization');
        return;
      }

      console.log('Starting Unity loading process');
      setIsUnityLoading(true);

      try {
        const files = [
          "/Build/lenticularapp.data.br",
          "/Build/lenticularapp.framework.js.br",
          "/Build/lenticularapp.wasm.br"
        ];

        for (const file of files) {
          console.log(`Checking file: ${file}`);
          try {
            const response = await fetch(file, { 
              cache: 'no-store',
              headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
              }
            });
            if (!response.ok) {
              throw new Error(`Failed to load ${file}: ${response.status} ${response.statusText}`);
            }
            await response.blob();
          } catch (error) {
            console.error(`Error checking file ${file}:`, error);
            setIsUnityLoading(false);
            return;
          }
        }

        const canvas = document.querySelector('#unity-canvas');
        if (!canvas) {
          console.error('Canvas element not found in DOM');
          throw new Error('Canvas element not found');
        }

        console.log('Starting Unity instance creation...');
        
        // Unity Loading Bar要素を追加
        const loadingBar = document.createElement('div');
        loadingBar.className = 'unity-loading-bar';
        loadingBar.innerHTML = `
          <div class="unity-progress-bar-empty">
            <div class="unity-progress-bar-full"></div>
          </div>
          <div class="unity-loading-text">Loading... 0%</div>
        `;
        canvas.parentElement.appendChild(loadingBar);

        const progressBar = loadingBar.querySelector('.unity-progress-bar-full');
        const loadingText = loadingBar.querySelector('.unity-loading-text');

        const instance = await window.createUnityInstance(canvas, {
          dataUrl: "/Build/lenticularapp.data.br",
          frameworkUrl: "/Build/lenticularapp.framework.js.br",
          codeUrl: "/Build/lenticularapp.wasm.br",
          streamingAssetsUrl: "StreamingAssets",
          companyName: "DefaultCompany",
          productName: "LenticularApp",
          productVersion: "0.1",
          cacheControl: (url) => {
            return 'no-store';
          },
        }, (progress) => {
          const percentage = Math.round(progress * 100);
          progressBar.style.width = `${percentage}%`;
          loadingText.textContent = `Loading... ${percentage}%`;
          console.log(`Unity loading progress: ${percentage}%`);
        });

        console.log("Unity loaded successfully, updating states");
        setUnityInstance(instance);
        setIsUnityLoaded(true);
        
        // ローディングバーを削除
        loadingBar.remove();
      } catch (error) {
        console.error("Unity failed to load:", error);
        setIsUnityLoading(false);
      }
    };

    // Step4に到達時、もしくは再初期化フラグが立っている場合に初期化を実行
    if (/*currentStep === STEPS.FINAL && shouldReinitUnity &&*/ !isUnityLoaded && !isUnityLoading) {
      console.log('Conditions met for Unity initialization');
      initUnity();
    } else {
      console.log('Conditions not met for Unity initialization:', {
        // isStep4: currentStep === STEPS.FINAL,
        // shouldReinit: shouldReinitUnity,
        notLoaded: !isUnityLoaded,
        notLoading: !isUnityLoading
      });
    }

    return;
  }, [isUnityLoaded, isUnityLoading]);

  // Add helper function to convert parameters
  const convertParamsForUnity = (warpRatio, warpSize) => {
    // Convert warpRatio (0~1.5) to integer (0~11)
    const ratioInt = Math.round((warpRatio / 1.5) * 11);
    
    // Convert warpSize (0~2.0) to integer (0~11)
    const sizeInt = Math.round((warpSize / 2.0) * 11);
    
    // Ensure values are within bounds
    const boundedRatio = Math.max(0, Math.min(11, ratioInt));
    const boundedSize = Math.max(0, Math.min(11, sizeInt));
    
    return `${boundedRatio},${boundedSize}`;
  };

  // Modify the Unity effect to use the converted parameters
  useEffect(() => {
    if (isUnityLoaded && unityInstance) {
      if (currentStep === STEPS.FINAL && savedColors) {
        // Get parameters from savedColors
        const warpRatios = savedColors.warpRatios;
        const warpSizes = savedColors.warpSizes;
        const colors = savedColors.colors;
        
        if (warpRatios && warpRatios.length > 0 && warpSizes && warpSizes.length > 0) {
          const unityParams = convertParamsForUnity(
            warpSizes[0],    // Use the first saved warpSize
            warpRatios[0]  // Use the first saved warpRatio
          );
          unityInstance.SendMessage('GamePlay', 'LocatePrefabByString', unityParams);

          // Send color information if available
          if (colors && colors.length >= 3) {
            const colorParams = `${colors[0]},${colors[1]},${colors[2]}`;
            unityInstance.SendMessage('GamePlay', 'SetMaterialColorByString', colorParams);
            console.log("Colors sent to Unity:", colorParams);
          }
        }
      } else {
        // Default values for non-final steps
        unityInstance.SendMessage('GamePlay', 'LocatePrefabByString', '0,0');
        console.log('Default values sent to Unity');
      }
    }
  }, [isUnityLoaded, unityInstance, currentStep, savedColors]);


  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    const generateGradients = async () => {
      // 最初のステップ以外は、保存されたパラメータを使用
      const selectedParams = currentStep !== STEPS.FIRST_SELECTION ? savedColors : null;

      // 進行中の生成をキャンセル
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      const allGradients = Array.from({ length: TOTAL_GRADIENTS }, () => {
        if (currentStep === STEPS.FIRST_SELECTION) {
          return {
            warpRatio: getRandomFloat(0, 1.5),
            warpSize: getRandomFloat(0, 2),
            noiseRatio: 0.08,
            bgColor: generateRandomColor(),
            colors: [generateRandomColor(), generateRandomColor()],
            numberPoints: 2,
            randomNumber: Math.random() * 100
          };
        } else {
          if (!selectedParams || selectedParams.colors.length < 3) {
            return {
              warpRatio: getRandomFloat(0, 1.5),
              warpSize: getRandomFloat(0, 2),
              noiseRatio: 0.08,
              bgColor: generateRandomColor(),
              colors: [generateRandomColor(), generateRandomColor()],
              numberPoints: 2,
              randomNumber: Math.random() * 100
            };
          }

          const shuffledColors = [...selectedParams.colors].sort(() => Math.random() - 0.5);
          const randomWarpRatio = getRandomFromArray(selectedParams.warpRatios);
          const randomWarpSize = getRandomFromArray(selectedParams.warpSizes);
          
          return {
            warpRatio: randomWarpRatio,
            warpSize: randomWarpSize,
            noiseRatio: 0.08,
            bgColor: shuffledColors[0],
            colors: [shuffledColors[1], shuffledColors[2]],
            numberPoints: 2,
            randomNumber: Math.random() * 100
          };
        }
      });

      const batchSize = 2;
      const batches = [];
      for (let i = 0; i < allGradients.length; i += batchSize) {
        batches.push(allGradients.slice(i, i + batchSize));
      }

      const newGradientImages = new Array(TOTAL_GRADIENTS).fill(null);
      const newGradients = new Array(TOTAL_GRADIENTS).fill(null);

      try {
        for (let i = 0; i < batches.length; i++) {
          // シグナルが中断されていたら生成を停止
          if (signal.aborted) {
            return;
          }

          const batch = batches[i];
          const batchImages = await generateGradientBatch(batch, i === 0);
          
          // シグナルが中断されていたら生成を停止
          if (signal.aborted) {
            return;
          }

          batchImages.forEach((image, batchIndex) => {
            if (image) {
              const globalIndex = i * batchSize + batchIndex;
              newGradientImages[globalIndex] = image;
              newGradients[globalIndex] = allGradients[globalIndex];
            }
          });

          // シグナルが中断されていない場合のみ状態を更新
          if (!signal.aborted) {
            setGradientImages([...newGradientImages]);
            setGradients([...newGradients]);
          }
        }

        // シグナルが中断されていない場合のみローディング状態を更新
        // if (!signal.aborted) {
        //   setLoading(false);
        // }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Gradient generation aborted');
        } else {
          console.error('Error generating gradients:', error);
        }
      }
    };

    generateGradients();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [currentStep, TOTAL_GRADIENTS, savedColors, regenerationKey]);

  useEffect(() => {
    if (savedColors?.colors) {
      const initialColors = [
        savedColors.colors[0], // background color
        savedColors.colors[1], // first gradient color
        savedColors.colors[2]  // second gradient color
      ];
      setColorList(initialColors);
      
      // 最初のカラーボックスを選択状態にする
      handleColorSelect(initialColors[0], 0);
    }
  }, [savedColors]);

  const handleColorSelect = (color, index) => {
    setSelectedColorIndex(index);
    selectedColorIndexRef.current = index;
    setSelectedColor(color);
    
    // Convert hex to HSL
    const r = parseInt(color.slice(1, 3), 16) / 255;
    const g = parseInt(color.slice(3, 5), 16) / 255;
    const b = parseInt(color.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const l = (max + min) / 2;
    let h, s;

    if (max === min) {
      h = 0;
      s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h *= 60;
    }

    // Set initial HSL values
    const newHSL = { h, s: s * 100, l: l * 100 };
    setCurrentHSL(newHSL);
  };

  const updateColorList = (newColor) => {
    setColorList(prevList => {
      const newList = [...prevList];
      newList[selectedColorIndex] = newColor;
      return newList;
    });
  };

  // Add effect to watch colorList changes
  useEffect(() => {
    if (isUnityLoaded && unityInstance && colorList.length >= 3) {
      const colorParams = `${colorList[0]},${colorList[1]},${colorList[2]}`;
      unityInstance.SendMessage('GamePlay', 'SetMaterialColorByString', colorParams);
    }
  }, [colorList, isUnityLoaded, unityInstance]);

  // Modify handleHueChange to only update colors
  const handleHueChange = (hue) => {
    const currentIndex = selectedColorIndexRef.current;
    if (currentIndex === null) return;

    setCurrentHSL(prev => {
      const newHSL = { ...prev, h: hue };
      const newHexColor = hslToHex(hue, prev.s, prev.l);
      
      setSelectedColor(newHexColor);
      
      setColorList(prevList => {
        const newList = [...prevList];
        newList[currentIndex] = newHexColor;
        return newList;
      });

      return newHSL;
    });
  };

  // Add function to fetch color names
  const fetchColorNames = async (colors, warpRatio, warpSize) => {
    setIsLoadingColorNames(true);
    try {
      const response = await fetch('https://ipa-color-namer-258456716942.asia-northeast1.run.app/process_message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: `${colors[0]}, ${colors[1]}, ${colors[2]}, ${warpRatio}, ${warpSize}`
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.colors && data.colors.length >= 5) {
        setColorNames({
          title: data.colors[0],
          options: data.colors.slice(1)
        });
      }
    } catch (error) {
      console.error('Error fetching color names:', error);
      setColorNames({ title: '', options: [] });
    } finally {
      setIsLoadingColorNames(false);
    }
  };

  const handleNextStep = () => {
    // 次のステップに進む前に、現在選択されているグラデーションのパラメータを保存
    const paramsForNextStep = extractParamsFromSelected();
    // console.log('Params saved for next step:', paramsForNextStep);
    
    // パラメータを保存
    setSavedColors(paramsForNextStep);
    
    const nextStep = currentStep + 1;
    currentStepRef.current = nextStep;
    
    if (nextStep === STEPS.FINAL) {
      const selectedIndex = Array.from(selectedGradients)[0];
      const selectedGradient = gradients[selectedIndex];
      
      if (selectedGradient) {
        // Convert warpRatio (0~1.5) to noiseValue (0~11)
        const newNoiseValue = Math.round((selectedGradient.warpRatio / 1.5) * 11);
        // Convert warpSize (0~2.0) to waveValue (0~11)
        const newWaveValue = Math.round((selectedGradient.warpSize / 2.0) * 11);
        
        setNoiseValue(newNoiseValue);
        setWaveValue(newWaveValue);
        
        // 選択された画像を保存
        const selectedImage = gradientImages[selectedIndex];
        if (selectedImage) {
          setSelectedFinalImage(selectedImage);
          
          // Fetch color names for the selected gradient
          const colors = [selectedGradient.bgColor, ...selectedGradient.colors];
          fetchColorNames(colors, selectedGradient.warpRatio, selectedGradient.warpSize);
        } else {
          console.error('Selected image is undefined');
        }
      }
    }
    
    // 状態をクリア
    setSelectedGradients(new Set());
    setGradients([]);
    setGradientImages([]);
    
    // setLoading(true);
    setCurrentStep(nextStep);

    // ステート更新後にスクロールを実行
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100);
  };

  const toggleGradient = (index) => {
    setSelectedGradients(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(index)) {
        newSelected.delete(index);
      } else {
        if (newSelected.size < MAX_SELECTIONS) {
          newSelected.add(index);
        }
      }
      return newSelected;
    });
  };

  // Re:Startボタンのクリックハンドラーを修正
  const handleRestart = async (e) => {
    console.log('Re:Start button clicked');
    e.currentTarget.blur();

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
    }

    // Unityインスタンスのクリーンアップ
    // if (unityInstance) {
    //   console.log('Cleaning up Unity instance');
    //   setUnityInstance(null);
    // }

    console.log('Resetting all states');
    // 他の状態をリセット
    currentStepRef.current = STEPS.FIRST_SELECTION;
    setSavedColors(null);
    setSelectedGradients(new Set());
    setGradients([]);
    setGradientImages([]);
    setSelectedFinalImage(null);
    setColorList([]);
    setSelectedColorIndex(null);
    // setLoading(true);
    setRegenerationKey(prev => prev + 1);
    setColorNames({ title: '', options: [] });

    // Unity関連の状態をリセット
    // setIsUnityLoaded(false);
    // setIsUnityLoading(false);
    // setShouldReinitUnity(false);

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    setCurrentStep(STEPS.FIRST_SELECTION);
  };

  // Add helper function to convert slider values back to original ranges
  const convertSliderValuesToOriginalRanges = (noiseVal, waveVal) => {
    // Convert noise value (0-11) to warpRatio (0-1.5)
    const warpRatio = (noiseVal / 11) * 1.5;
    
    // Convert wave value (0-11) to warpSize (0-2.0)
    const warpSize = (waveVal / 11) * 2.0;
    
    return {
      warpRatio: Math.min(1.5, Math.max(0, warpRatio)),
      warpSize: Math.min(2.0, Math.max(0, warpSize))
    };
  };

  const handleRename = async () => {
    if (colorList && colorList.length >= 3) {
      const { warpRatio, warpSize } = convertSliderValuesToOriginalRanges(noiseValue, waveValue);
      await fetchColorNames(
        colorList,
        warpRatio,
        warpSize
      );
    }
  };

  // colorNamesの状態を更新する関数を修正
  const handleOptionClick = (selectedOption, clickedIndex) => {
    setColorNames(prev => {
      const newOptions = [...prev.options];
      newOptions[clickedIndex] = prev.title;  // クリックされた位置に現在のタイトルを配置
      return {
        ...prev,
        title: selectedOption,
        options: newOptions
      };
    });
  };

  return (
    <>
      <header className="header">
        <div className="header-top">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            padding: '0 40px',
            position: 'relative'
          }}>
            <h1 className="header-title" style={{ 
              marginRight: '40px',
              margin: '0'
            }}>
              <img 
                src={require('../assets/logo.png')} 
                alt="inquid"
                style={{
                  height: '24px', // ロゴの高さを調整
                  width: 'auto',
                  display: 'block'
                }}
              />
            </h1>
            <div style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '40px',
              marginLeft: '40px',
              minWidth: 0
            }}>
              <div style={{
                flex: 1,
                height: '15px',
                display: 'flex',
                backgroundColor: 'transparent',
                borderRadius: '0px',
                minWidth: 0,
                opacity: MAX_SELECTIONS === 0 ? 0 : 1
              }}>
                {selectedGradients.size > 0 && Array.from(selectedGradients).map((selectedIndex, index) => (
                  <div
                    key={index}
                    style={{
                      flex: 1,
                      height: '100%',
                      backgroundColor: 'transparent',
                      overflow: 'hidden'
                    }}
                  >
                    {gradientImages[selectedIndex] && (
                      <img
                        src={gradientImages[selectedIndex]}
                        alt=""
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'fill'
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div style={{
                color: 'white',
                fontSize: '14px',
                letterSpacing: '1px',
                whiteSpace: 'nowrap',
                minWidth: '100px',
                textAlign: 'right'
              }}>
                {MAX_SELECTIONS > 0 && `${MAX_SELECTIONS - selectedGradients.size} ${MAX_SELECTIONS - selectedGradients.size === 1 ? 'image' : 'images'} left`}
              </div>
              <button
                style={{
                  backgroundColor: selectedGradients.size === MAX_SELECTIONS ? STEP_CONFIG[currentStep].backgroundColor : '#CCCCCC',
                  color: 'white',
                  padding: '8px 24px',
                  border: 'none',
                  borderRadius: '4px',
                  fontSize: '14px',
                  cursor: selectedGradients.size === MAX_SELECTIONS ? 'pointer' : 'not-allowed',
                  transition: 'background-color 0.3s',
                  opacity: MAX_SELECTIONS === 0 ? 0 : (selectedGradients.size === MAX_SELECTIONS ? 1 : 0.7),
                  minWidth: '160px',
                  whiteSpace: 'nowrap',
                  letterSpacing: '1px'
                }}
                onClick={() => {
                  if (selectedGradients.size === MAX_SELECTIONS) {
                    handleNextStep();
                  }
                }}
                disabled={selectedGradients.size !== MAX_SELECTIONS}
              >
                {currentStep === STEPS.THIRD_SELECTION ? 'FINISH' : 'NEXT STEP →'}
              </button>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="header-content">
            <div className="step-info">
              <div className="step-text">STEP {currentStep}/4</div>
              <div className="step-highlight">
                {STEP_CONFIG[currentStep].training && `- ${STEP_CONFIG[currentStep].training} -`}
              </div>
              <div className="step-dots">
                {[1, 2, 3, 4].map(step => (
                  <div 
                    key={step} 
                    className={`step-dot ${step === currentStep ? 'active' : ''}`}
                    style={step === currentStep ? {
                      backgroundColor: currentStep === STEPS.FINAL 
                        ? (savedColors?.colors?.[0] || '#E0E0E0')
                        : STEP_CONFIG[currentStep].backgroundColor
                    } : {}}
                  />
                ))}
              </div>
            </div>
            <h2 className="page-title">
              {currentStep === STEPS.FINAL ? (
                <span>{STEP_CONFIG[currentStep].title}</span>
              ) : (
                <>
                  <span>Choose</span>
                  <span className="page-title-number">{STEP_CONFIG[currentStep].required}</span>
                  <span>images</span>
                </>
              )}
            </h2>
            <div className="page-subtitle">
              <div className="subtitle-text-container">{STEP_CONFIG[currentStep].subtitle}</div>
              <div className="restart-button-container">
                <button
                  className="restart-button"
                  onClick={handleRestart}
                >
                  <i className="fas fa-sync-alt"></i>
                  Re:Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div 
        className={`App ${currentStep === STEPS.FINAL ? 'final-step' : ''}`}
        style={{
          backgroundColor: STEP_CONFIG[currentStep].backgroundColor || 
            (currentStep === STEPS.FINAL ? savedColors?.colors?.[0] : '#E0E0E0')
        }}
      >
        <div className="final-step-layout" style={{ display: currentStep === STEPS.FINAL ? 'block' : 'none' }}>
          <div className="final-step-container">
            <div className="final-step-content">
              <div className="final-step-top">
                <div className="final-step-gradient">
                  {selectedFinalImage && (
                    <img src={selectedFinalImage} alt="Selected gradient" />
                  )}
                </div>
                <div className="final-step-webgl">
                  <div className="unity-canvas-container" ref={unityCanvasRef}>
                    <canvas id="unity-canvas" style={{ width: '100%', height: '100%', background: '#231F20' }}></canvas>
                  </div>
                </div>
              </div>
              <div className="final-step-bottom">
                <div className="final-step-color-info" style={{ backgroundColor: savedColors?.colors?.[1] || '#ffffff' }}>
                  {isLoadingColorNames ? (
                    <div className="loading-spinner-container">
                      <i className="fas fa-spinner fa-spin"></i>
                      <div className="loading-text">Generating names...</div>
                    </div>
                  ) : (
                    <>
                      <div className="color-name-label">COLOR NAME</div>
                      <h1 className="color-name-title">{colorNames.title}</h1>
                      <div className="color-name-subtitle">Other options...</div>
                      <ul className="color-options-list">
                        {colorNames.options.map((option, index) => (
                          <li 
                            key={index} 
                            className="color-option-item"
                            onClick={() => handleOptionClick(option, index)}
                            style={{ cursor: 'pointer' }}
                          >
                            <span>{index + 1}</span>{option}
                          </li>
                        ))}
                      </ul>
                      <button className="rename-button" onClick={handleRename}>
                        <i className="fas fa-sync-alt"></i>
                        Re:Name
                      </button>
                    </>
                  )}
                </div>
                <div className="final-step-controls">
                  <div className="final-step-color-controls">
                    <div className="final-step-color-controls-inner">
                      <div className="color-picker-list">
                        {colorList.map((color, index) => (
                          <div
                            key={index}
                            className={`color-list-item ${selectedColorIndex === index ? 'selected' : ''}`}
                            style={{ backgroundColor: color }}
                            onClick={() => handleColorSelect(color, index)}
                          />
                        ))}
                      </div>
                      <div className="color-picker-container" style={{ backgroundColor: selectedColor }}>
                        <div className="color-picker-wrapper" style={{ '--current-hue': currentHSL.h, '--current-saturation': `${currentHSL.s}%`, '--current-lightness': `${currentHSL.l}%` }}>
                          <ColorPicker
                            hue={currentHSL.h}
                            alpha={1}
                            onInput={handleHueChange}
                            onSelect={handleHueChange}
                          />
                        </div>
                        <div className="gradient-slider-wrapper">
                          <input
                            type="range"
                            className="gradient-slider"
                            min="0"
                            max="100"
                            value={currentHSL.s}
                            style={{
                              '--track-background': `linear-gradient(to right, 
                                ${hslToHex(currentHSL.h, 0, currentHSL.l)} 0%, 
                                ${hslToHex(currentHSL.h, 100, currentHSL.l)} 100%)`,
                              '--current-hue': currentHSL.h,
                              '--current-saturation': `${currentHSL.s}%`,
                              '--current-lightness': `${currentHSL.l}%`
                            }}
                            onChange={(e) => {
                              if (selectedColorIndex === null) return;
                              const value = parseInt(e.target.value);

                              setCurrentHSL(prev => ({ ...prev, s: value }));
                              const newHexColor = hslToHex(currentHSL.h, value, currentHSL.l);
                              setSelectedColor(newHexColor);

                              setColorList(prevList => {
                                const newList = [...prevList];
                                newList[selectedColorIndex] = newHexColor;

                                // Send updated colors to Unity
                                if (unityInstance && newList.length >= 3) {
                                  const colorParams = `${newList[0]},${newList[1]},${newList[2]}`;
                                  unityInstance.SendMessage('GamePlay', 'SetMaterialColorByString', colorParams);
                                }

                                return newList;
                              });
                            }}
                          />
                        </div>
                        <div className="gradient-slider-wrapper">
                          <input
                            type="range"
                            className="gradient-slider"
                            min="0"
                            max="100"
                            value={100 - currentHSL.l}
                            style={{
                              '--track-background': `linear-gradient(to right, 
                                #ffffff 0%, 
                                ${hslToHex(currentHSL.h, currentHSL.s, 50)} 50%, 
                                #000000 100%)`,
                              '--current-hue': currentHSL.h,
                              '--current-saturation': `${currentHSL.s}%`,
                              '--current-lightness': `${currentHSL.l}%`
                            }}
                            onChange={(e) => {
                              if (selectedColorIndex === null) return;
                              const value = 100 - parseInt(e.target.value);

                              setCurrentHSL(prev => ({ ...prev, l: value }));
                              const newHexColor = hslToHex(currentHSL.h, currentHSL.s, value);
                              setSelectedColor(newHexColor);

                              setColorList(prevList => {
                                const newList = [...prevList];
                                newList[selectedColorIndex] = newHexColor;

                                // Send updated colors to Unity
                                if (unityInstance && newList.length >= 3) {
                                  const colorParams = `${newList[0]},${newList[1]},${newList[2]}`;
                                  unityInstance.SendMessage('GamePlay', 'SetMaterialColorByString', colorParams);
                                }

                                return newList;
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="final-step-shape-controls">
                    <div className="shape-control-item">
                      <span>Noise</span>
                      <div className="icon">
                        <img src={require('../assets/noise-min.png')} alt="Noise min" />
                      </div>
                      <input 
                        type="range" 
                        min="0" 
                        max="11" 
                        step="1"
                        value={noiseValue}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          setNoiseValue(value);
                          if (unityInstance) {
                            // Send shape parameters
                            const unityParams = `${waveValue},${value}`;
                            unityInstance.SendMessage('GamePlay', 'LocatePrefabByString', unityParams);
                            
                            // Send current colors
                            if (colorList && colorList.length >= 3) {
                              const colorParams = `${colorList[0]},${colorList[1]},${colorList[2]}`;
                              unityInstance.SendMessage('GamePlay', 'SetMaterialColorByString', colorParams);
                            }
                          }
                        }}
                      />
                      <div className="icon">
                        <img src={require('../assets/noise-max.png')} alt="Noise max" />
                      </div>
                    </div>
                    <div className="shape-control-item">
                      <span>Wave</span>
                      <div className="icon">
                        <img src={require('../assets/wave-min.png')} alt="Wave min" />
                      </div>
                      <input 
                        type="range" 
                        min="0" 
                        max="11" 
                        step="1"
                        value={waveValue}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          setWaveValue(value);
                          if (unityInstance) {
                            // Send shape parameters
                            const unityParams = `${value},${noiseValue}`;
                            unityInstance.SendMessage('GamePlay', 'LocatePrefabByString', unityParams);
                            
                            // Send current colors
                            if (colorList && colorList.length >= 3) {
                              const colorParams = `${colorList[0]},${colorList[1]},${colorList[2]}`;
                              unityInstance.SendMessage('GamePlay', 'SetMaterialColorByString', colorParams);
                            }
                          }
                        }}
                      />
                      <div className="icon">
                        <img src={require('../assets/wave-max.png')} alt="Wave max" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="final-step-buttons">
              <button className="final-step-button save">Save</button>
              <button className="final-step-button buy">Buy</button>
            </div>
          </div>
        </div>
        
        <div className="gallery-layout" style={{ display: currentStep === STEPS.FINAL ? 'none' : 'block' }}>
          <div className="gallery-container">
            <div className="gradient-grid">
              {Array.from({ length: TOTAL_GRADIENTS }).map((_, index) => (
                <div 
                  key={index} 
                  className="gradient-item"
                  onClick={() => toggleGradient(index)}
                  style={{ 
                    cursor: selectedGradients.size < MAX_SELECTIONS || selectedGradients.has(index) ? 'pointer' : 'not-allowed' 
                  }}
                >
                  <div 
                    className="gradient-item-background" 
                    style={{ 
                      backgroundColor: selectedGradients.has(index) ? STEP_CONFIG[currentStep].borderColor : 'white' 
                    }}
                  />
                  {gradientImages[index] && gradients[index] && (
                    <div className="color-info">
                      <div className="color-row">
                        <div className="color-box" style={{ backgroundColor: gradients[index].bgColor }} />
                        <span className="color-hex">{gradients[index].bgColor}</span>
                      </div>
                      {gradients[index].colors.map((color, colorIndex) => (
                        <div key={colorIndex} className="color-row">
                          <div className="color-box" style={{ backgroundColor: color }} />
                          <span className="color-hex">{color}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  {gradientImages[index] ? (
                    <img 
                      src={gradientImages[index]} 
                      alt={`Gradient ${index + 1}`}
                      className="gradient-image"
                      style={{
                        width: selectedGradients.has(index) ? '70%' : '100%',
                        height: selectedGradients.has(index) ? '70%' : '100%',
                        top: selectedGradients.has(index) ? '15%' : '0',
                        left: selectedGradients.has(index) ? '15%' : '0'
                      }}
                    />
                  ) : (
                    <div className="loading-placeholder" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GradientGallery; 