import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GradientGallery from './components/GradientGallery';
import GradientCreator from './components/GradientCreator';
import TopPage from './components/TopPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TopPage />} />
        <Route path="/generate" element={<GradientGallery />} />
        <Route path="/create" element={<GradientCreator />} />
      </Routes>
    </Router>
  );
}

export default App;